import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/marcodamm/personal/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Home" keywords={['gatsby', 'application', 'react']} mdxType="SEO" />
    <h2>{`About Me`}</h2>
    <p>{`I am a full stack web developer from Germany. Having studied East-Asian Studies
at the University of Heidelberg I worked in Japan for a few years and dipped my
toes into both the Sales and Procurement side of corporate life.`}</p>
    <p>{`Tinkering with hard- and software having been my passion for the longest part
of my life, I quickly took on junior development responsibilities and began
developing lean software solutions for cross-department usage.`}</p>
    <p>{`I relocated to Germany in 2010 with the intention making my passion my work.
Since then I have been working hard on constantly improving my software
development skills on both, the backend and frontend sides of web development.`}</p>
    <p>{`I currently work as a senior full stack web developer and team lead in Cologne,
Germany. I have been working with well proven software stacks like LAMP and
more recent stacks like the MERN stack. My latest full stack project uses
geocoding and mapping middleware to visualize corporate social responsibility
and commitment. On occasion I do freelance work as well.`}</p>
    <p>{`I also love to read. I read a wide variety of fiction and non-fiction, although
one could argue that I heavily favour the science-fiction genre of the former.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“It is what you read when you don't have to that determines what you will be
when you can't help it.”`}</p>
      <p parentName="blockquote">{`— `}<cite>{`Oscar Wilde`}</cite></p>
    </blockquote>
    <p>{`If you are curios about what I read you can look at `}<Link to="/books/" mdxType="Link">{`my reading list`}</Link></p>
    <h2>{`Experience`}</h2>
    <p>{`I have enjoyed working for both, big corporate employers and small start ups. I
feel at home in different cultures and enjoy traveling. Besides having studied
the humanities I also see myself as a capable software developer.`}</p>
    <p>{`I have been leading a small start up development team since early 2017 and have
been advocating and implementing major departmental changes in both
development-specific and broad cross-departmental areas.`}</p>
    <p>{`I make a point of not only knowing my specific area of expertise, but also
constantly expanding my knowledge in other domains. Right now I am working on
better understanding Japanese management practices and visual design work.`}</p>
    <h2>{`Skills`}</h2>
    <ul>
      <li parentName="ul">{`Building modern full stack applications NodeJS, React, Ruby, AWS Cloud, etc.`}</li>
      <li parentName="ul">{`Leadership`}</li>
      <li parentName="ul">{`Constant quest for knowledge`}</li>
      <li parentName="ul">{`Stereotype-defying social skills`}</li>
      <li parentName="ul">{`Fluent in English, German and Japanese`}</li>
    </ul>
    <p>{`A lot of code I have written for employers and customers is hidden behind NDAs
but if you are feeling adventurous you can have a look at my public
`}<a parentName="p" {...{
        "href": "https://gitlab.com/marcodamm/",
        "title": "Marco's public GitLab repositories"
      }}>{`GitLab`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://github.com/marcodamm",
        "title": "Marco's public GitHub repositories"
      }}>{`GitHub`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://bitbucket.org/wntrmt/",
        "title": "Marco's public Bitbucket Repositories"
      }}>{`Bitbucket`}</a>{` repositories.`}</p>
    <p>{`If you made it to here, let me first applaud your patience. If you still have
questions or comments about me or my work, then please feel free to `}<Link to="/contact/" mdxType="Link">{`contact me`}</Link>{`.`}</p>
    <p>{`PS: If you are a recruiter and would like to extend a job offer, please make
sure I am a good fit for the position you are advertising `}<em parentName="p">{`before`}</em>{` contacting
me.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      